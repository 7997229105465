/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        //GSAP
        $( document ).ready(function() {
          tl = new TimelineMax();
           tl.to($(".whitey"), 1.5, {opacity: "0"}, 0)
           tl.to($(".whitey"), 1, {display: "none"}, 1.5)
        });
        

        //GSAP END
        

        $(document).foundation(); // Foundation JavaScript

        $('a[data-rel^=lightcase]').lightcase();

        function randomNumber(min, max){
          return Math.floor(Math.random() * (1 + max - min) + min);
        }

        

        // //gsap

        // window.onload = function(){
        //     var bg = $(".bgrand")
        //     TweenMax.to(bg, 50, {backgroundPosition:"-500px, -500px", ease:Linear.easeNone, force3D:true});
        // }

        // //gsap end

        $("header .menu li").each(function(){
          $(this).prepend("<div class='box lightrand'></div>")
        });
        $(".lightrand, .bgrand, footer, .fixbgrand").each(function(){
          $(this).addClass("c"+ randomNumber(1, 5) )
          $(this).css("background-position-x", randomNumber(0, 50))
          $(this).css("background-position-y", randomNumber(0, 50))
        })
        $("a .gkast").each(function(){
          $(this).addClass("c"+ randomNumber(1, 5) )
        })

        $(".mobkast").click(function(){
          $(this).toggleClass("active");
          $(".downer").slideToggle("fast");
          $(".lang").toggleClass("active");
          $(".eulogo").toggleClass("deactive")
        })

        $i=0;
        $(".menu-item-has-children").each(function(){
          $i++;
          $(this).addClass("cut" + $i)
          $(this).find(".menu").addClass("cut" + $i)
        })
        $u=0;
        $(".menu-item-has-children").each(function(){
          $u++;
          $(this).find(".cut" + $u).appendTo(".cuts");
          $(".menu.cut" + $u).prepend("<div class='backcover'><div class='back'></div></div>");
        });
        $(".backtext").appendTo(".menu .back");

        $(".menu-item.cut1 a").click(function(e){
          e.preventDefault();
          $(".cuts").toggleClass("active");
          $(".cuts .cut1").toggleClass("active");
        })

        $(".menu-item.cut2 a").click(function(e){
          e.preventDefault();
          $(".cuts").toggleClass("active");
          $(".cuts .cut2").toggleClass("active");
        })

        $(".menu-item.cut3 a").click(function(e){
          e.preventDefault();
          $(".cuts").toggleClass("active");
          $(".cuts .cut3").toggleClass("active");
        })

        $(".backtext").click(function(){
          $(".cuts.active").removeClass("active");
          $(".cuts .menu.active").removeClass("active");
        })




        //anchor scroll
        $(function() {
          $('a[href*=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html,body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
